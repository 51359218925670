
const routers = [
  // {
  //   path: '/',
  //   component: () => import('@/views/dashboard/Index'),
  //   children: [
  //     // Dashboard
  //     {
  //       name: 'Dashboard',
  //       path: '',
  //       component: () => import('@/views/dashboard/Dashboard')
  //     }
  //   ]
  // }
]

export default routers
