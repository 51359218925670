const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

const kebab = str => {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

const toggleFullScreen = () => {
  const doc = window.document
  const docEl = doc.documentElement

  const requestFullScreen =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
  const cancelFullScreen =
    doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl)
  } else {
    cancelFullScreen.call(doc)
  }
}

const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) { return pair[1] }
  }
  return ''
}

const isWebView = () => {
  return window.isAndroidWebView() || window.isWebKit()
}

function getGAClientID() {
  var match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)')
  var raw = match ? decodeURIComponent(match[1]) : null
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/)
  }
  return (match) ? match[1] : null
}

export {
  getGAClientID,
  randomElement,
  toggleFullScreen,
  kebab,
  getQueryVariable,
  isWebView
}
