import AuthLayout from '@/components/layouts/AuthLayout'

const constantRouter = [
  {
    path: '/',
    component: AuthLayout,
    meta: { title: 'My Channel' },
    redirect: '/auth/mychannel',
    hidden: true
  }
]

export default constantRouter
