import Vue from 'vue'
import Router from 'vue-router'

import demoRouter from './demo'
import constantRouter from './constant'
import asyncRouter from './async'

Vue.use(Router)

const routers = constantRouter.concat(demoRouter).concat(asyncRouter)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routers
})
