
const state = {
  sub_data: [],
  sub_discounts: {
    '1': 1,
    '6': 0.8,
    '12': 0.7
  }
}

const mutations = {
  SET_SUB_DATA: (state, data) => {
    state.sub_data = data
  }
}

const actions = {
  // user login
  set_sub_data({ commit }, data) {
    commit('SET_SUB_DATA', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

