const getters = {
  device: state => state.app.device,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  user_id: state => state.user.user_id,
  userInfo: state => state.user.userInfo,
  sub_discounts: state => state.channel.sub_discounts
}

export default getters
