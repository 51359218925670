import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyDmc3l53JilKZ0NlxtvzMxN_IF47f_Uypw',
  authDomain: window.location.host,
  // authDomain: 'one-channel-one.firebaseapp.com',
  databaseURL: 'https://one-channel-one.firebaseio.com',
  projectId: 'one-channel-one',
  storageBucket: 'one-channel-one.appspot.com',
  messagingSenderId: '703014706139',
  appId: '1:703014706139:web:fc84458b4c1e8b687125fe',
  measurementId: 'G-588FHKWQ6K'
}
firebase.initializeApp(firebaseConfig)
// firebase.analytics()

export default firebase
