import request from '@/util/request'

export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

export function addPayment(data) {
  return request({
    url: '/user/add_payment_method',
    method: 'post',
    data
  })
}

export function getPaymentList() {
  return request({
    url: '/user/get_payments',
    method: 'get'
  })
}

export function subscription(data) {
  return request({
    url: '/subscription/generator',
    method: 'post',
    data
  })
}

export function getChannel() {
  return request({
    url: '/channel/list',
    method: 'get'
  })
}

export function getUserIfno(params) {
  return request({
    url: '/user/detail',
    method: 'get',
    params
  })
}

export function trailing(data) {
  return request({
    url: '/subscription/trailing',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/basic/logout/',
    method: 'post'
  })
}

export function userReport(data) {
  return request({
    url: '/accounting/analytics/userreport',
    method: 'post',
    data
  })
}

export function getUserOrderList(params) {
  return request({
    url: '/subscription/user_order/',
    method: 'get',
    params
  })
}

export function getSettingsList(params) {
  return request({
    url: '/appconfig/profile_menus',
    method: 'get',
    params
  })
}

export function checkDefaultPayment(data) {
  return request({
    url: '/user/check_default_payment',
    method: 'post',
    data
  })
}

export function userTrialExtend(data) {
  return request({
    url: '/subscription/extended_trial',
    method: 'post',
    data
  })
}

export function emailUnSbuscribeReason(data) {
  return request({
    url: '/appconfig/email_unsubscribe_reason',
    method: 'post',
    data
  })
}

export function getTextByCode(params) {
  return request({
    url: '/appconfig/get_code_info',
    method: 'get',
    params
  })
}

export function getPaymentMethods(params) {
  return request({
    url: '/user/get_payments',
    method: 'get',
    params
  })
}

export function changeDefaultPayment(data) {
  return request({
    url: '/user/change_default_payment',
    method: 'post',
    data
  })
}

export function getLatestAppInfo(params) {
  return request({
    url: '/appconfig/app_info',
    method: 'get',
    params
  })
}

export function getProfileInfo(params) {
  return request({
    url: '/user/profileinfo',
    method: 'get',
    params
  })
}

export function updateProfileInfo(data) {
  return request({
    url: '/user/profileinfo',
    method: 'post',
    data
  })
}

export function updatePaymentMethod(data) {
  return request({
    url: '/user/paymentmethod',
    method: 'put',
    data
  })
}

export function getInviteCount(params) {
  return request({
    url: '/referral/invite_info',
    method: 'get',
    params
  })
}

export function getPointsHistory(params) {
  return request({
    url: '/integral/integral_record',
    method: 'get',
    params
  })
}

export function redeemPoints(data) {
  return request({
    url: '/integral/integral_exchange',
    method: 'post',
    data
  })
}

export function getAccount(data) {
  return request({
    url: '/channel/get_account',
    method: 'post',
    data
  })
}

export function resetpwd(data) {
  return request({
    url: '/channel/resetpwd',
    method: 'post',
    data
  })
}

export function getUserBalance(data) {
  return request({
    url: '/user/user_balance',
    method: 'get',
    data
  })
}

export function topUp(data) {
  return request({
    url: '/subscription/topup',
    method: 'post',
    data
  })
}

export function manualSub(data) {
  return request({
    url: '/subscription/manualsub',
    method: 'post',
    data
  })
}

export function getChannelDailyPrice(data) {
  return request({
    url: '/subscription/channelprice',
    method: 'get',
    data
  })
}

export function getManualSubList(data) {
  return request({
    url: '/subscription/manualsublist',
    method: 'get',
    data
  })
}

export function cancelSub(data) {
  return request({
    url: '/subscription/cancel',
    method: 'post',
    data
  })
}

export function getTopupHistory(data) {
  return request({
    url: '/subscription/topuphistory',
    method: 'get',
    data
  })
}

export function getVerificationCode(data) {
  return request({
    url: '/channel/getCode',
    method: 'post',
    data
  })
}

export function fetchNetflixEmail(data) {
  return request({
    url: '/channel/fetch_netflix_email',
    method: 'post',
    data
  })
}
