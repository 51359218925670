import Cookies from 'js-cookie'

const auth_token = 'jwt'
const device_from = 'from'

export function getToken() {
  return Cookies.get(auth_token)
}

export function setToken(token) {
  return Cookies.set(auth_token, token)
}

export function removeToken() {
  return Cookies.remove(auth_token)
}

export function clearAuth() {
  Cookies.remove(auth_token)
}

export function isAuth() {
  return Cookies.get(auth_token)
}

export function setDeviceFrom(val) {
  return Cookies.set(device_from, val)
}

export function getDeviceFrom() {
  return Cookies.get(device_from)
}
