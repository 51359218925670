<template>
  <v-app class="primary split-bg">
    <v-content>
      <v-container :class="{'not-login-page-path':!isLogin}" class="container-box" fluid fill-height>
        <v-layout justify-center>
          <v-flex xs12 sm10 md6 lg5 xl3>
            <router-view :key="$route.path" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      isLogin: true
    }
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      if (to.name !== 'login') {
        this.isLogin = false
      } else {
        this.isLogin = true
      }
    }
  },
  created() {
    console.log(this.$route.name)
  },
  methods: {}
}
</script>
<style scoped lang="scss">
.split-bg {
  // height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.container{
    background: black;
    padding: 0px;
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 768px) {
  .container-box{
    background: black;
    // padding: 0px;
    // background: url(https://www.onechannel.one/wp-content/uploads/2021/04/Sign-In-with-Google.jpg) rgba(0,0,0, 0.8);
    // background-size:cover;
    // background-blend-mode: multiply;
  }
}

.not-login-page-path{
  background: black!important;
}
</style>
