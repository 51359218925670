<template>
  <v-app class="primary">
    <router-view :key="$route.path" />
  </v-app>
</template>

<script>
export default {
  name: 'CommonLayout'
}
</script>

<style lang="scss" scoped>

</style>
