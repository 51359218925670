import axios from 'axios'
import store from '@/store'
import { getToken } from '@/util/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'JWT ' + getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    /**
  * code为非20000是抛错 可结合自己业务进行修改
  */
    const res = response.data
    // if ((res.code && res.code !== 200) || (response.status !== 200 && response.status !== 201)) {
    if (response.status < 200 && response.status > 201) {
      return Promise.reject('error')
    } else {
      return res
    }
  },
error => { // eslint-disable-line
    console.log('err' + error) // for debug
    // return Promise.resolve([])
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    if (error.response.status === 403 || error.response.status === 401) {
      store.dispatch('user/FedLogOut') // 前端登出清除auth info
    }
    return Promise.reject(error)
  }

)

export default service
