import Vue from 'vue'
import Vuetify, { VApp, VContent, VContainer, VAvatar, VOverlay, VRow, VCol, VSimpleTable, VDivider, VDialog, VCard, VCardText, VImg } from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  components: {
    VApp, VContent, VContainer, VAvatar, VOverlay, VRow, VCol, VSimpleTable, VDivider, VDialog, VCard, VCardText, VImg
  },
  directives: {
    Ripple
  }
})

const theme = {
  // primary: '#3f51b5',
  primary: '#E040FB',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3'
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  },
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  }
})
