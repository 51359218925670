<template>
  <div class="back-icon" @click="goBack">
    <v-btn icon color="purple accent-2" x-large>
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

  </div>
</template>

<script>
import { getDeviceFrom } from '@/util/auth'

export default {
  name: 'RouterBack',
  methods: {
    goBack() {
      const from = getDeviceFrom()
      if (from === 'ios') {
        window.webkit.messageHandlers.backHomePage.postMessage('success')
      } else if (from === 'android') {
        // TODO: android
      } else {
        this.$router.back()
      }

      //
    }
  }
}
</script>

<style lang="scss" scoped>
.back-icon{
    position: relative;
    top:15px;
    z-index: 2;
    button{
      background-color: transparent!important;
    }
}
</style>
