import router from './router'
// eslint-disable-next-line
import store from './store' 
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
// eslint-disable-next-line
import { isAuth, getToken, setDeviceFrom, getDeviceFrom } from '@/util/auth' // get token from cookie
import getPageTitle from '@/util/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

// eslint-disable-next-line
const whiteList = [
  '/auth/login',
  '/auth/emailauth',
  '/auth/trialextend',
  '/auth/resetpwd',
  '/marketing/unsubscribe',
  '/auth/survey',
  '/auth/returnurl'
] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  intercom_default_launcher(to.path)

  if (isAuth()) {
    if (to.path === '/auth/login') {
      // if is logged in, redirect to the home page
      next({ path: '/auth/mychannel' })
      NProgress.done()
    } else {
      const hasGetUserInfo = store.getters.name
      if (hasGetUserInfo) {
        next()
        NProgress.done()
      } else {
        try {
          // get user info
          await store.dispatch('user/getInfo')
          next()
          NProgress.done()
        } catch (error) {
          store.dispatch('user/FedLogOut').then(() => {
            next(`/auth/login?redirect=${to.path}`)
            NProgress.done()
          })
        }
      }
    }
  } else {
    /* has no token*/

    // if (to.query && to.query.from && ['ios', 'android'].includes(to.query.from)) {
    //   whiteList.push('/auth/mychannel')
    // }

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // next(`/auth/login?redirect=${to.path}`)
      // debugger
      if (hasQueryParams(to)) {
        next({ name: 'login', query: to.query })
      } else {
        next(`/auth/login?redirect=${to.path}`)
      }

      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

// function isMobile() {
//   if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//     return true
//   } else {
//     return false
//   }
// }

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

function intercom_default_launcher(path) {
  const results = ['mychannel', 'signup', 'login'].filter(item => path.includes(item))
  window.Intercom('update', {
    'hide_default_launcher': results.length === 0
  })
}
